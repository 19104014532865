import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
    key: 0,
    class: "footer-bar-mobile-nav"
};
const _hoisted_2 = ["href"];
const _hoisted_3 = ["href"];
const _hoisted_4 = ["href"];
const _hoisted_5 = ["href"];
export default /*@__PURE__*/ _defineComponent({
    __name: 'FooterBarMobileNav',
    props: {
        open: {
            type: Boolean,
            default: false,
            required: true
        },
        privacyPolicyLink: {
            type: String,
            default: '',
            required: true
        },
        privacyPolicyText: {
            type: String,
            default: 'Privacy Policy'
        },
        trademarkText: {
            type: String,
            default: 'Trademark'
        },
        termsOfUseText: {
            type: String,
            default: 'Terms Of Use'
        },
        carrersText: {
            type: String,
            default: 'Careers'
        },
        trademarkLink: {
            type: String,
            default: '/trademark'
        },
        termsOfUseLink: {
            type: String,
            default: '/support/terms-of-use'
        },
        careersLink: {
            type: String,
            default: 'https://careers.zumba.com'
        }
    },
    setup(__props) {
        const props = __props;
        return (_ctx, _cache) => {
            return (props.open)
                ? (_openBlock(), _createElementBlock("ul", _hoisted_1, [
                    _createElementVNode("li", null, [
                        _createElementVNode("a", {
                            href: props.privacyPolicyLink
                        }, _toDisplayString(props.privacyPolicyText), 9, _hoisted_2)
                    ]),
                    _createElementVNode("li", null, [
                        _createElementVNode("a", {
                            href: props.trademarkLink
                        }, _toDisplayString(props.trademarkText), 9, _hoisted_3)
                    ]),
                    _createElementVNode("li", null, [
                        _createElementVNode("a", {
                            href: props.termsOfUseLink
                        }, _toDisplayString(props.termsOfUseText), 9, _hoisted_4)
                    ]),
                    _createElementVNode("li", null, [
                        _createElementVNode("a", {
                            href: props.careersLink
                        }, _toDisplayString(props.carrersText), 9, _hoisted_5)
                    ])
                ]))
                : _createCommentVNode("", true);
        };
    }
});
