import { post } from "@ts/Util/http";
import { currentUserLocale } from "@ts/Util/i18n";
import { readPageData } from "@ts/Util/page";
import { csrfToken } from "@ts/Util/security";
if (readPageData('authenticated') && !readPageData('timezone')) {
    //add this extra check since there might be browsers that still has this issue
    //@see https://support.google.com/chrome/thread/237667177/macos-sonoma-14-chrome-is-reporting-wrong-time-due-to-undefined-timezone?hl=en
    if (Intl.DateTimeFormat().resolvedOptions().timeZone) {
        const locale = currentUserLocale() ?? 'en-US';
        const url = `/${locale}/account/set_timezone`;
        post(url, csrfToken(), {
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        })
            .then(response => {
            if (!response.ok) {
                throw new Error('Cannot set timezone');
            }
        })
            .catch(error => {
            console.warn(error);
        });
    }
}
