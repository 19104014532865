<template>
    <div class="nav-mobile">
        <slot name="header" />

        <slot name="recent-activity" />

        <slot name="usermenu" />

        <slot name="student" />

        <slot name="publicmenu" />

        <slot name="language" />

        <slot name="country" />

        <slot name="logout" />
    </div>
</template>

<style scoped lang="scss">
    .nav-mobile {
        height: 100%;
        background-color: var(--zumba-gray-700);
    }

    .theme-strong {
        .nav-mobile {
            background-color: var(--strong-primary-gray);
        }
    }
</style>
