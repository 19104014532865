import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = { class: "nav-mobile-profile" };
const _hoisted_2 = { class: "nav-mobile-icons" };
const _hoisted_3 = ["href"];
const _hoisted_4 = ["href"];
import MembershipIconSVG from '@icons/navigation/ic_memberships.svg';
import SettingsIconSVG from '@icons/navigation/ic_settings.svg';
import { theme, ThemeType } from "@ts/Util/theme";
export default /*@__PURE__*/ _defineComponent({
    __name: 'NavHeaderMobile',
    props: {
        profileName: { type: String, default: '' },
        isPreMembership: { type: Boolean, default: false },
        myAccountUrl: {
            type: Object,
            required: true
        },
        statusCardUrl: {
            type: Object,
            required: true
        },
    },
    setup(__props) {
        const isZumbaThemed = theme.value === ThemeType.Zumba;
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("span", {
                    class: _normalizeClass(["nav-mobile-profile-name", [isZumbaThemed ? ['z-b4', 'f-inter'] : 'z-b3']])
                }, _toDisplayString(__props.profileName), 3),
                _createElementVNode("div", _hoisted_2, [
                    (!__props.isPreMembership)
                        ? (_openBlock(), _createElementBlock("a", _mergeProps({
                            key: 0,
                            href: __props.statusCardUrl.href
                        }, __props.statusCardUrl.attributes, {
                            target: "_blank",
                            class: "status-card"
                        }), [
                            _createVNode(_unref(MembershipIconSVG), { class: "nav-mobile-icon" })
                        ], 16, _hoisted_3))
                        : _createCommentVNode("", true),
                    _createElementVNode("a", _mergeProps({
                        href: __props.myAccountUrl.href
                    }, __props.myAccountUrl.attributes), [
                        _createVNode(_unref(SettingsIconSVG), { class: "nav-mobile-icon" })
                    ], 16, _hoisted_4)
                ])
            ]));
        };
    }
});
