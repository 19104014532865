import { defineComponent as _defineComponent } from 'vue';
import { createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue";
const _hoisted_1 = { class: "z-footer-bar" };
const _hoisted_2 = { class: "z-footer-bar__links" };
const _hoisted_3 = ["href"];
const _hoisted_4 = ["href"];
const _hoisted_5 = ["href"];
const _hoisted_6 = ["href"];
const _hoisted_7 = ["srcset"];
const _hoisted_8 = ["src", "alt"];
const _hoisted_9 = { class: "z-footer-bar__copyright" };
import { reactive } from 'vue';
import ZumbitoSVG from '@icons/footer/zumbito.svg';
import ArrowSVG from '@icons/arrow.svg';
import FooterBarMobileNav from '@components/Footer/FooterBarMobileNav.vue';
const mobileWidthBreakpoint = 480;
export default /*@__PURE__*/ _defineComponent({
    __name: 'ZFooterBar',
    props: {
        t: { default: () => ({
                privacyPolicy: 'Privacy Policy',
                trademark: 'Trademark',
                termsOfUse: 'Terms Of Use',
                careers: 'Careers'
            }) },
        localeSelectionRedirectLink: { default: '/en-US/localization?redirect=%2Fen-US' },
        countryCode: { default: 'us' },
        countryWebpBase64: { default: 'UklGRrYAAABXRUJQVlA4IKoAAABwAgCdASoQAAsAAgA0JbACdAb4DkqQhc3CBqfAAP63YIz8qTX+DsIAtspzkjbllBn5vr/E6RyzU74/u/Y71fLaA53FTsFv5Bw2D5Mdnx/8ycV0vn/ioRPkJF+ECZCziHyA/U7lqEfrn52fSXllRV+fjEtDtDqx2FNMkDYnZj1L5lAsf/jqpvcs+WATlstUL++7uxX9MoMU+4UdUfn165i/8KR3BGde/xIAAA==' },
        privacyPolicyLink: { default: '/support/policy/privacy' },
        termsOfUseLink: { default: '/support/terms-of-use' },
        careersLink: { default: 'https://careers.zumba.com' },
        trademarkLink: { default: '/trademark' },
        hideMobileMenu: { type: Boolean, default: false }
    },
    setup(__props) {
        const props = __props;
        const state = reactive({ accordionLinksOppened: false });
        const dynamicYear = new Date().getFullYear();
        const handleLangIconClick = () => {
            if (document.body.offsetWidth > mobileWidthBreakpoint) {
                window.location.href = '/localization?redirect=' + props.localeSelectionRedirectLink;
                return;
            }
        };
        const handleArrowIconClick = () => {
            document.body.offsetWidth <= mobileWidthBreakpoint && (state.accordionLinksOppened = !state.accordionLinksOppened);
        };
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock(_Fragment, null, [
                _createVNode(FooterBarMobileNav, {
                    open: state.accordionLinksOppened,
                    "privacy-policy-link": props.privacyPolicyLink,
                    "privacy-policy-text": props.t.privacyPolicy,
                    "trademark-link": props.trademarkLink,
                    "trademark-text": props.t.trademark,
                    "terms-of-use-link": props.termsOfUseLink,
                    "terms-of-use-text": props.t.termsOfUse,
                    "careers-link": props.careersLink,
                    "carrers-text": props.t.careers
                }, null, 8, ["open", "privacy-policy-link", "privacy-policy-text", "trademark-link", "trademark-text", "terms-of-use-link", "terms-of-use-text", "careers-link", "carrers-text"]),
                _createElementVNode("section", _hoisted_1, [
                    _createVNode(_unref(ZumbitoSVG)),
                    _createElementVNode("div", null, [
                        _createElementVNode("ul", _hoisted_2, [
                            _createElementVNode("li", null, [
                                _createElementVNode("a", {
                                    href: props.privacyPolicyLink
                                }, _toDisplayString(props.t.privacyPolicy), 9, _hoisted_3)
                            ]),
                            _createElementVNode("li", null, [
                                _createElementVNode("a", {
                                    href: props.trademarkLink
                                }, _toDisplayString(props.t.trademark), 9, _hoisted_4)
                            ]),
                            _createElementVNode("li", null, [
                                _createElementVNode("a", {
                                    href: props.termsOfUseLink
                                }, _toDisplayString(props.t.termsOfUse), 9, _hoisted_5)
                            ]),
                            _createElementVNode("li", null, [
                                _createElementVNode("a", {
                                    href: props.careersLink,
                                    rel: "noopener",
                                    target: "_blank"
                                }, _toDisplayString(props.t.careers), 9, _hoisted_6)
                            ]),
                            _createElementVNode("li", null, [
                                _createElementVNode("a", {
                                    onClick: _withModifiers(handleLangIconClick, ["prevent"])
                                }, [
                                    _createElementVNode("picture", null, [
                                        _createElementVNode("source", {
                                            srcset: ('data:image/webp;base64,' + props.countryWebpBase64),
                                            type: "image/webp"
                                        }, null, 8, _hoisted_7),
                                        _createElementVNode("img", {
                                            class: "flag-only",
                                            src: '/img/blt/icons/country-flags/' + props.countryCode + '.png',
                                            alt: props.countryCode.toUpperCase()
                                        }, null, 8, _hoisted_8)
                                    ])
                                ])
                            ])
                        ]),
                        _createElementVNode("div", _hoisted_9, [
                            _createElementVNode("p", null, "© Copyright " + _toDisplayString(_unref(dynamicYear)) + " Zumba Fitness, LLC.", 1),
                            (!_ctx.hideMobileMenu)
                                ? (_openBlock(), _createElementBlock("a", {
                                    key: 0,
                                    onClick: _withModifiers(handleArrowIconClick, ["prevent"])
                                }, [
                                    _createVNode(_unref(ArrowSVG), {
                                        class: _normalizeClass({
                                            'z-footer-bar__copyright__greater-sign': !state.accordionLinksOppened,
                                            'z-footer-bar__copyright__greater-sign--oppened': state.accordionLinksOppened
                                        })
                                    }, null, 8, ["class"])
                                ]))
                                : _createCommentVNode("", true)
                        ])
                    ])
                ])
            ], 64));
        };
    }
});
