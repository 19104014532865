import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers } from "vue";
const _hoisted_1 = { class: "gf-email-subscribe" };
const _hoisted_2 = { class: "gf-footer-nav-title gf-email-subscribe-title" };
const _hoisted_3 = {
    key: 0,
    class: "gf-email-subscribe-success"
};
const _hoisted_4 = {
    key: 1,
    class: "gf-email-subscribe-wrapper"
};
const _hoisted_5 = {
    key: 2,
    class: "gf-email-subscribe-error"
};
const _hoisted_6 = { class: "gf-unsubscribe-notice" };
import { reactive, ref } from 'vue';
import { post } from '@ts/Util/http';
import { isoCountryCode, prefixLocaleURL } from '@ts/Util/i18n';
import ZInput from '@components/Core/ZInput.vue';
import ZButton from '@components/Core/ZButton.vue';
import { csrfToken } from '@ts/Util/security';
import { dataLayer } from '@ts/Util/analytics';
export default /*@__PURE__*/ _defineComponent({
    __name: 'FooterNewsletterForm',
    props: {
        source: {
            type: String,
            default: 'site-footer',
        },
        t: {
            type: Object,
            default: () => ({
                title: 'Get the latest Zumba News, Promos & Events.',
                thankYouMessage: 'Thanks for Joining!',
                inputPlaceholder: 'Enter your email',
                buttonText: 'Join',
                invalidEmailText: 'The email address you entered is invalid.',
                unsubscribeNoticeText: 'You can unsubscribe at any time.'
            })
        }
    },
    setup(__props) {
        const newsletterForm = ref('newsletterForm');
        const state = reactive({
            subscribed: false,
            error: false
        });
        const props = __props;
        const onSubmit = async (e) => {
            const email = e.target.elements['email'].value;
            state.error = false;
            const response = await post(prefixLocaleURL('/account/maillist_subscribe'), csrfToken(), {
                email: email,
                source: props.source,
            });
            dataLayer.push({
                event: 'gaEvent',
                category: 'Cat',
                action: 'Signup',
                label: isoCountryCode()
            });
            if (response.ok) {
                state.subscribed = true;
                e.target.elements['email'].value = '';
            }
            else {
                state.error = true;
            }
        };
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("aside", _hoisted_1, [
                _createElementVNode("h3", _hoisted_2, _toDisplayString(props.t.title), 1),
                _createElementVNode("form", {
                    ref_key: "newsletterForm",
                    ref: newsletterForm,
                    class: "gf-email-subscribe-form",
                    onSubmit: _withModifiers(onSubmit, ["prevent"])
                }, [
                    (state.subscribed)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                            _createElementVNode("span", null, _toDisplayString(props.t.thankYouMessage), 1)
                        ]))
                        : _createCommentVNode("", true),
                    (!state.subscribed)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                            _createVNode(ZInput, {
                                class: "gf-email-subscribe-input",
                                label: props.t.inputPlaceholder,
                                type: "email",
                                "input-id": "email",
                                name: "email",
                                required: "",
                                onInvalid: _cache[0] || (_cache[0] = () => state.error = true)
                            }, null, 8, ["label"]),
                            _createVNode(ZButton, {
                                class: "gf-email-subscribe-z-button",
                                "data-ga4-event-name": "subscribe_lead",
                                "data-ga4-action": "Join Click"
                            }, {
                                default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(props.t.buttonText), 1)
                                ]),
                                _: 1
                            })
                        ]))
                        : _createCommentVNode("", true),
                    (state.error)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(props.t.invalidEmailText), 1))
                        : _createCommentVNode("", true),
                    _createElementVNode("p", _hoisted_6, _toDisplayString(props.t.unsubscribeNoticeText), 1)
                ], 544)
            ]));
        };
    }
});
