import { createApp, defineComponent } from 'vue/dist/vue.esm-bundler';
import TopButton from "@components/Core/TopButton.vue";
const ScrollingLinks = defineComponent({
    name: 'ScrollingLinks',
    components: {
        TopButton
    }
});
if (document.getElementById('scrolling-links')) {
    createApp(ScrollingLinks).mount('#scrolling-links');
}
