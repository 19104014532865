import { createApp } from 'vue/dist/vue.esm-bundler';
import RatingHandler from '@modules/Rating/RatingHandler.vue';
import { defineComponent } from "vue";
const ClassRatingComponent = defineComponent({
    name: 'ClassRatingComponent',
    components: {
        RatingHandler
    }
});
const classrating = document.getElementById('class-rating-component');
if (classrating) {
    createApp(ClassRatingComponent).mount('#class-rating-component');
}
