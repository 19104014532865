import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import ZButton from "@components/Core/ZButton.vue";
import { reactive, onMounted } from 'vue';
import { __z } from '@ts/Util/i18n';
export default /*@__PURE__*/ _defineComponent({
    __name: 'TopButton',
    setup(__props) {
        const handleClick = () => {
            document.getElementById('layout-page-content')?.scrollIntoView();
        };
        const state = reactive({
            isActive: false,
            isStuck: false
        });
        onMounted(() => {
            addHeaderObserver();
            addFooterObserver();
        });
        const addHeaderObserver = () => {
            const observerOptions = { attributes: true };
            const toggleIsActive = (mutationList) => {
                state.isActive = mutationList[0].target.classList.contains('sticky');
            };
            document.addEventListener("DOMContentLoaded", function () {
                const headerElement = document.getElementsByTagName('header')[0];
                const observer = new MutationObserver(toggleIsActive);
                observer.observe(headerElement, observerOptions);
            }, { once: true });
        };
        const addFooterObserver = () => {
            const observerOptions = { threshold: 0 };
            document.addEventListener("DOMContentLoaded", () => {
                const footerElement = document.getElementsByTagName('footer')[0];
                const toggleIsStuck = (entries) => { state.isStuck = entries[0].isIntersecting; };
                const observer = new IntersectionObserver(toggleIsStuck, observerOptions);
                observer.observe(footerElement);
            }, { once: true });
        };
        return (_ctx, _cache) => {
            return (_openBlock(), _createBlock(ZButton, {
                href: "#",
                class: _normalizeClass(["back-to-top", {
                        'btt-active': state.isActive,
                        'btt-stuck': state.isStuck
                    }]),
                variety: "robin",
                wide: "never",
                onClick: handleClick
            }, {
                default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_unref(__z)('globals/TOP', 'Top')), 1)
                ]),
                _: 1
            }, 8, ["class"]));
        };
    }
});
