import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = { key: 0 };
const _hoisted_2 = { key: 0 };
import { computed, ref } from 'vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ShowMore',
    props: {
        t: {
            type: Object,
            default: () => ({
                moreLinkText: 'More',
                lessLinkText: 'Less'
            })
        },
        paragraphContent: { type: String, default: '' },
        limit: { type: Number, default: 100 }
    },
    setup(__props) {
        const props = __props;
        const isContentLongEnough = computed(() => props.paragraphContent.length > props.limit);
        const content = computed(() => showMore.value ? props.paragraphContent : props.paragraphContent.substring(0, props.limit));
        const contentRef = ref();
        const moreLinkRef = ref();
        const lessLinkRef = ref();
        const showMore = ref(!isContentLongEnough.value);
        const toggleShowMore = () => showMore.value = !showMore.value;
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("p", null, [
                _createElementVNode("span", {
                    ref_key: "contentRef",
                    ref: contentRef
                }, _toDisplayString(content.value), 513),
                (isContentLongEnough.value)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
                        (!showMore.value)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
                                _cache[2] || (_cache[2] = _createTextVNode(" ... ")),
                                _cache[3] || (_cache[3] = _createElementVNode("br", null, null, -1)),
                                _createElementVNode("span", {
                                    ref_key: "moreLinkRef",
                                    ref: moreLinkRef,
                                    class: "show-more",
                                    onClick: _cache[0] || (_cache[0] = ($event) => (toggleShowMore()))
                                }, _toDisplayString(props.t.moreLinkText), 513)
                            ]))
                            : _createCommentVNode("", true),
                        (showMore.value)
                            ? (_openBlock(), _createElementBlock("a", {
                                key: 1,
                                ref_key: "lessLinkRef",
                                ref: lessLinkRef,
                                class: "show-less",
                                onClick: _cache[1] || (_cache[1] = ($event) => (toggleShowMore()))
                            }, _toDisplayString(props.t.lessLinkText), 513))
                            : _createCommentVNode("", true)
                    ]))
                    : _createCommentVNode("", true)
            ]));
        };
    }
});
