import { mixpanelServiceTrack } from '@ts/Util/analytics';
const trackedPages = [
    'become-an-instructor',
    'become-a-zumba-instructor',
    'online-program'
];
window.addEventListener("load", function () {
    setTimeout(setHubChatListener, 5000);
});
function setHubChatListener() {
    if (window.HubSpotConversations) {
        window.HubSpotConversations.on('conversationStarted', payload => {
            let path = window.location.pathname;
            let page = path.split("/").pop();
            let pageName;
            // @ts-ignore
            if (trackedPages.includes(page)) {
                pageName = page;
            }
            else {
                pageName = path;
            }
            mixpanelServiceTrack('TrackChattedWithChatbot', {
                'pageName': pageName
            });
        });
    }
}
