import { createApp, defineComponent } from 'vue/dist/vue.esm-bundler';
import EuPrivacyPolicyPopup from '@components/Core/EUPrivacyPolicyPopup.vue';
const PrivacyPolicyComponent = defineComponent({
    name: 'PrivacyPolicyComponent',
    components: {
        EuPrivacyPolicyPopup
    }
});
if (document.getElementById('privacy-policy-component')) {
    createApp(PrivacyPolicyComponent).mount("#privacy-policy-component");
}
