import { getMixpanelDistinctID, mixpanelServiceTrack, splitPagePath } from '@ts/Util/analytics';
import { readPageData } from '@ts/Util/page';
import { readCookie } from '@ts/Util/cookies';
window.addEventListener("load", function () {
    if (getMixpanelDistinctID()) {
        const swivelMap = readPageData('swivelMap');
        const swivelBucket = readCookie('Swivel_Bucket');
        const trackAllPagesEnabled = swivelMap['Mixpanel.PageViewTracksAllPages']?.includes(Number(swivelBucket));
        const trackPagesClicks = [
            'become-an-instructor',
            'become-a-zumba-instructor',
            'online-program',
            'training',
            'app-subscription',
            'app-subscription/signup',
            'app-subscription/signup-confirm',
            'dashboard',
            'membership'
        ];
        const trackPageViews = [
            '/dashboard',
            '/class_search',
            '/member/class_management',
            '/member/class_management/students',
            '/training/refer',
            '/app-subscription',
            '/become-an-instructor',
            '/become-a-zumba-instructor',
            '/online-program',
            '/trainings',
            '/trainings/jump_start_gold',
            '/survey_flow',
            '/job-board'
        ];
        const trackPageViewsPatterns = [
            { pattern: '/p/', slug: 'instructor_page' },
            { pattern: '/class_detail/', slug: 'class_detail' },
            { pattern: '/class_detail/index/', slug: 'class_detail' },
            { pattern: '/training', slug: 'training' },
            { pattern: '/job-board/job', slug: 'job-board/job' },
            { pattern: '/member/addon', slug: 'member_addon' },
        ];
        const pagesExcludedFromTracking = [
            'app-subscription/onboarding',
            'app-subscription/authenticate',
            'app-subscription/signup',
            'profile/subscription',
            'zumba_app/wellhub-landing',
        ];
        let path = window.location.pathname;
        let page = splitPagePath();
        let pageName;
        let tracked = false;
        //Track page views
        if (page && trackPageViews.some(url => path.endsWith(url))) {
            mixpanelServiceTrack('TrackPageViewed', { pageName: page });
            tracked = true;
        }
        //Track custom urls with parameters
        if (path && !tracked && trackPageViewsPatterns.some(url => path.includes(url.pattern))) {
            const pageSlug = trackPageViewsPatterns.find(url => path.includes(url.pattern))?.slug || '';
            mixpanelServiceTrack('TrackPageViewed', { pageName: pageSlug });
            tracked = true;
        }
        // Tracks all urls that weren't specified before
        if (trackAllPagesEnabled && path && !tracked && !pagesExcludedFromTracking.some(url => path.endsWith(url))) {
            mixpanelServiceTrack('TrackPageViewed', { pageName: path });
        }
        //Track clicks on elements with data-mixpanel-command-name and data-mixpanel-properties
        // @ts-ignore
        if (trackPagesClicks.includes(page)) {
            pageName = page;
        }
        else {
            pageName = path;
        }
        document.addEventListener('click', (ev) => {
            let target = ev.target;
            do {
                if (target?.dataset['mixpanelCommandName']) {
                    const properties = JSON.parse(target.dataset['mixpanelProperties'] || "{}");
                    properties['pageName'] = target?.dataset['mixpanelPageName'] ?? pageName;
                    mixpanelServiceTrack(target.dataset['mixpanelCommandName'], properties);
                    break;
                }
            } while (target = target ? target.parentElement : null);
        });
    }
});
