import { defineComponent as _defineComponent } from 'vue';
import { withModifiers as _withModifiers, createElementVNode as _createElementVNode, unref as _unref, renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
    key: 0,
    class: "privacy-policy"
};
import { ref } from 'vue';
import { addCookie } from '@ts/Util/cookies';
import { theme, ThemeType } from "@ts/Util/theme";
export default /*@__PURE__*/ _defineComponent({
    __name: 'EUPrivacyPolicyPopup',
    setup(__props) {
        const showPopup = ref(true);
        const handleOnClose = () => {
            const now = new Date();
            now.setFullYear(now.getFullYear() + 1);
            addCookie('eu_privacy', '1', now);
            showPopup.value = false;
        };
        return (_ctx, _cache) => {
            return (showPopup.value)
                ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
                    _createElementVNode("a", {
                        class: "close",
                        onClick: _withModifiers(handleOnClose, ["prevent"])
                    }),
                    _createElementVNode("p", {
                        class: _normalizeClass({ 'f-inter': _unref(theme) === _unref(ThemeType).Zumba })
                    }, [
                        _renderSlot(_ctx.$slots, "default")
                    ], 2)
                ]))
                : _createCommentVNode("", true);
        };
    }
});
