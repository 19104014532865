import { createApp, defineComponent } from 'vue/dist/vue.esm-bundler';
import CarouselShared from "@components/Core/CarouselShared.vue";
import ShowMore from "@components/Core/ShowMore.vue";
const CarouselSharedContainer = defineComponent({
    name: 'CarouselSharedContainer',
    components: {
        CarouselShared,
        ShowMore
    }
});
// In some pages (/about) the component needs to be mounted more than once
document.querySelectorAll('.carousel-shared-container').forEach(element => {
    createApp(CarouselSharedContainer).mount(element);
});
