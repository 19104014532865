import { defineComponent as _defineComponent } from 'vue';
import { renderSlot as _renderSlot, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, mergeProps as _mergeProps, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue";
const _hoisted_1 = ["href"];
import { computed, useSlots } from 'vue';
import LockedIconSVG from '@icons/navigation/ic_locked.svg';
import { useIconLoader } from '@ts/Navigation/useIconLoader';
import Tooltip from '@components/Navigation/NavTooltip.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'NavItemMobile',
    props: {
        id: { default: '' },
        title: { default: '' },
        href: {},
        icon: { default: '' },
        locked: { type: Boolean, default: false },
        attributes: { default: () => ({}) },
        isTopLevel: { type: Boolean, default: false },
        active: { type: Boolean, default: false },
        lockedMessage: { default: '' }
    },
    emits: ['clicked'],
    setup(__props, { emit: __emit }) {
        const props = __props;
        const emit = __emit;
        const link = computed(() => {
            if (props.locked) {
                return '#';
            }
            return props.href;
        });
        const { iconSvg } = useIconLoader(props.icon);
        const handleClick = (event) => {
            if (props.locked) {
                event.preventDefault();
                event.stopPropagation();
            }
            else {
                emit('clicked', props);
            }
        };
        const slots = useSlots();
        const hasCustomSlot = computed(() => !!slots.default);
        return (_ctx, _cache) => {
            return (_openBlock(), _createBlock(_resolveDynamicComponent(props.isTopLevel ? 'div' : 'li'), { class: "nav-item" }, {
                default: _withCtx(() => [
                    _createVNode(Tooltip, {
                        content: props.locked && props.lockedMessage ? props.lockedMessage : ''
                    }, {
                        trigger: _withCtx(() => [
                            _createElementVNode("a", _mergeProps({
                                href: link.value,
                                class: ["z-b3", {
                                        'locked': props.locked,
                                        'active': props.active,
                                        'is-top-level': props.isTopLevel,
                                        'custom-slot': hasCustomSlot.value
                                    }]
                            }, props.attributes, { onClick: handleClick }), [
                                _renderSlot(_ctx.$slots, "default", {}, () => [
                                    (props.locked)
                                        ? (_openBlock(), _createBlock(_unref(LockedIconSVG), {
                                            key: 0,
                                            class: "nav-item-icon locked"
                                        }))
                                        : _createCommentVNode("", true),
                                    (_unref(iconSvg) && !props.locked)
                                        ? (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(iconSvg)), {
                                            key: 1,
                                            class: "nav-item-icon"
                                        }))
                                        : _createCommentVNode("", true),
                                    _createTextVNode(" " + _toDisplayString(props.title), 1)
                                ])
                            ], 16, _hoisted_1)
                        ]),
                        _: 3
                    }, 8, ["content"])
                ]),
                _: 3
            }));
        };
    }
});
