import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers } from "vue";
const _hoisted_1 = { class: "header-toggle" };
const _hoisted_2 = { class: "brands" };
const _hoisted_3 = ["href", "data-ga-action", "data-ga-label", "data-ga4-action", "data-ga4-details"];
const _hoisted_4 = ["href", "data-ga-action", "data-ga-label", "data-ga4-action", "data-ga4-details"];
const _hoisted_5 = ["data-ga-label", "data-ga4-details"];
const _hoisted_6 = { class: "ht-settings" };
const _hoisted_7 = { class: "ht-secondary-nav" };
const _hoisted_8 = { class: "ht-secondary-nav-item" };
const _hoisted_9 = { class: "ht-secondary-nav-item" };
const _hoisted_10 = { class: "dropdown-mtv dd-right" };
const _hoisted_11 = ["href", "data-ga-label", "data-ga4-details"];
const _hoisted_12 = ["href", "data-ga-label", "data-ga4-details"];
const _hoisted_13 = ["href", "data-ga-label", "data-ga4-details"];
const _hoisted_14 = ["href", "data-ga-label", "data-ga4-details"];
const _hoisted_15 = ["href", "data-ga-label", "data-ga4-details"];
const _hoisted_16 = ["href", "data-ga-label", "data-ga4-details"];
const _hoisted_17 = ["href", "data-ga-label", "data-ga4-details"];
const _hoisted_18 = ["href", "data-ga-label", "data-ga4-details"];
const _hoisted_19 = { key: 0 };
const _hoisted_20 = ["href", "data-ga-label", "data-ga4-details"];
import Profile from './Profile.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'HeaderToggle',
    props: {
        t: {
            type: Object,
            default: () => ({
                englishText: 'English',
                italianText: 'Italian',
                frenchText: 'French',
                spanishText: 'Spanish',
                portugueseText: 'Portuguese',
                deutschText: 'Deutsch',
                koreanText: 'Korean',
                indonesianText: 'Indonesian',
                signInText: 'Sign In',
                zinHomeText: 'ZIN™ Home',
                myDashboardText: 'My Dashboard',
                joinZinText: 'Join ZIN™',
                updatePaymentText: 'Update Payment',
                instructorTrainingsText: 'Instructor Trainings',
                sixWeekProgramText: '6 Week Program',
                myTrainingsText: 'My Trainings',
                manageMyAccountText: 'Manage My Account',
                myAccountText: 'My Account',
                signOutText: 'Sign Out',
                myAnnouncementsText: 'My Announcements',
                marketingMaterialsText: 'Marketing Materials',
                findAnInstructorText: 'Find An Instructor',
                findAnInstructorGymText: 'Find An Instructor',
                manageInstructorsText: 'Manage Instructors',
                hostATrainingText: 'Host a Training',
                zumbaForGymsText: 'Zumba For Gyms',
                gymAuditionKitText: 'Gym Audition Kit',
                manageLocationsText: 'Manage Locations',
                accountSummaryText: 'Account Summary',
                gymsProfileText: 'Gyms Profile',
                zumbiniText: 'Zumbini',
                userLanguageText: 'English',
                logInText: 'Log In',
                signUpText: 'Sign Up',
            })
        },
        canAccessMemberMenu: { type: String, default: '' },
        canAccessSixWeeksProgramProfile: { type: String, default: '' },
        canConsumerViewDashboard: { type: String, default: '' },
        canGymOwnerViewDashboard: { type: String, default: '' },
        canManageGymProfile: { type: String, default: '' },
        canManageLinkedGymInstructors: { type: String, default: '' },
        current: { type: String, default: '' },
        currentUserLocale: { type: String, default: '' },
        gaLabel: { type: String, default: '' },
        gymActiveLink: { type: String, default: '' },
        isAuthenticated: { type: String, default: '' },
        isCmsStrongNationEnabled: { type: String, default: '' },
        isIndonesianLanguageEnabled: { type: Boolean, default: false },
        isTrainedNonMember: { type: String, default: '' },
        isZumbiniGlobalHeaderEnabled: { type: Boolean, default: false },
        itemClass: { type: String, default: '' },
        linkClass: { type: String, default: '' },
        loginUrl: { type: String, default: '' },
        signUpUrl: { type: String, default: '' },
        profileName: { type: String, default: '' },
        shouldShowJoinLink: { type: String, default: '' },
        userHasDelinquentMembership: { type: String, default: '' },
        strongDataName: { type: String, default: '' },
        strongLink: { type: String, default: '' },
        uri: { type: String, default: '' },
        userHasBenefitMemberPortal: { type: String, default: '' },
        zinResponsiveLink: { type: String, default: '' },
        zumbaDataName: { type: String, default: '' },
        zumbaLink: { type: String, default: '' },
        isAclHeaderEnabled: { type: Boolean, default: false },
        isJapaneseEnabled: { type: Boolean, default: false },
        canViewStudentDashboard: {
            type: Boolean,
            default: false
        },
        isPrePaymentAccessEnabled: { type: String, default: '' },
    },
    setup(__props) {
        const props = __props;
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("section", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("a", {
                        href: props.zumbaLink,
                        class: _normalizeClass(["ht-brand ht-zumba", { 'active': props.current == 'zumba' }]),
                        "data-ga-category": "Global Navigation",
                        "data-ga-action": props.zumbaDataName,
                        "data-ga-label": props.uri,
                        "data-ga4-event-name": "global_navigation",
                        "data-ga4-action": props.zumbaDataName,
                        "data-ga4-details": props.uri
                    }, _toDisplayString(props.zumbaDataName), 11, _hoisted_3),
                    _createElementVNode("a", {
                        href: props.strongLink,
                        class: _normalizeClass(["ht-brand ht-strong-nation", {
                                'ht-strong-nation': props.isCmsStrongNationEnabled,
                                'ht-strong': !props.isCmsStrongNationEnabled,
                                'active': props.current == 'strong'
                            }]),
                        "data-ga-category": "Global Navigation",
                        "data-ga-action": props.strongDataName,
                        "data-ga-label": props.uri,
                        "data-ga4-event-name": "global_navigation",
                        "data-ga4-action": props.strongDataName,
                        "data-ga4-details": props.uri
                    }, _toDisplayString(props.strongDataName), 11, _hoisted_4),
                    (props.isZumbiniGlobalHeaderEnabled)
                        ? (_openBlock(), _createElementBlock("a", {
                            key: 0,
                            href: "https://www.zumbini.com",
                            class: "ht-zumbini ht-brand",
                            "data-ga-category": "Global Navigation",
                            "data-ga-action": "Zumbini",
                            "data-ga-label": props.uri,
                            "data-ga4-event-name": "global_navigation",
                            "data-ga4-action": "Zumbini",
                            "data-ga4-details": props.uri,
                            target: "_blank",
                            rel: "noopener"
                        }, _toDisplayString(props.t.zumbiniText), 9, _hoisted_5))
                        : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("ul", _hoisted_7, [
                        _createElementVNode("li", _hoisted_8, [
                            _createVNode(Profile, {
                                t: props.t,
                                current: props.current,
                                "can-access-member-menu": props.canAccessMemberMenu,
                                "can-access-six-weeks-program-profile": props.canAccessSixWeeksProgramProfile,
                                "can-consumer-view-dashboard": props.canConsumerViewDashboard,
                                "can-gym-owner-view-dashboard": props.canGymOwnerViewDashboard,
                                "can-manage-gym-profile": props.canManageGymProfile,
                                "can-manage-linked-gym-instructors": props.canManageLinkedGymInstructors,
                                "current-user-locale": props.currentUserLocale,
                                "ga-label": props.gaLabel,
                                "gym-active-link": props.gymActiveLink,
                                "is-authenticated": props.isAuthenticated,
                                "is-trained-non-member": props.isTrainedNonMember,
                                "item-class": props.itemClass,
                                "link-class": props.linkClass,
                                "login-url": props.loginUrl,
                                "sign-up-url": props.signUpUrl,
                                "profile-name": props.profileName,
                                "should-show-join-link": props.shouldShowJoinLink,
                                "user-has-delinquent-membership": props.userHasDelinquentMembership,
                                "user-has-benefit-member-portal": props.userHasBenefitMemberPortal,
                                "zin-responsive-link": props.zinResponsiveLink,
                                "is-acl-header-enabled": props.isAclHeaderEnabled,
                                "can-view-student-dashboard": props.canViewStudentDashboard,
                                "is-pre-payment-access-enabled": props.isPrePaymentAccessEnabled
                            }, null, 8, ["t", "current", "can-access-member-menu", "can-access-six-weeks-program-profile", "can-consumer-view-dashboard", "can-gym-owner-view-dashboard", "can-manage-gym-profile", "can-manage-linked-gym-instructors", "current-user-locale", "ga-label", "gym-active-link", "is-authenticated", "is-trained-non-member", "item-class", "link-class", "login-url", "sign-up-url", "profile-name", "should-show-join-link", "user-has-delinquent-membership", "user-has-benefit-member-portal", "zin-responsive-link", "is-acl-header-enabled", "can-view-student-dashboard", "is-pre-payment-access-enabled"])
                        ]),
                        _createElementVNode("li", _hoisted_9, [
                            _createElementVNode("a", {
                                href: "#",
                                class: "ht-user-session lang",
                                onClick: _cache[0] || (_cache[0] = _withModifiers(() => { }, ["prevent"]))
                            }, [
                                _createTextVNode(_toDisplayString(props.t.userLanguageText) + " ", 1),
                                _cache[1] || (_cache[1] = _createElementVNode("span", { class: "chevron down white" }, null, -1))
                            ]),
                            _createElementVNode("ul", _hoisted_10, [
                                _createElementVNode("li", null, [
                                    _createElementVNode("a", {
                                        href: '/en-US' + props.uri,
                                        "data-ga-category": "Global Language Switch",
                                        "data-ga-action": "English",
                                        "data-ga-label": props.gaLabel,
                                        "data-ga4-event-name": "global_navigation",
                                        "data-ga4-action": "English",
                                        "data-ga4-details": props.gaLabel
                                    }, _toDisplayString(props.t.englishText), 9, _hoisted_11)
                                ]),
                                _createElementVNode("li", null, [
                                    _createElementVNode("a", {
                                        href: '/fr-FR' + props.uri,
                                        "data-ga-category": "Global Language Switch",
                                        "data-ga-action": "French",
                                        "data-ga-label": props.gaLabel,
                                        "data-ga4-event-name": "global_navigation",
                                        "data-ga4-action": "French",
                                        "data-ga4-details": props.gaLabel
                                    }, _toDisplayString(props.t.frenchText), 9, _hoisted_12)
                                ]),
                                _createElementVNode("li", null, [
                                    _createElementVNode("a", {
                                        href: '/it-IT' + props.uri,
                                        "data-ga-category": "Global Language Switch",
                                        "data-ga-action": "Italian",
                                        "data-ga-label": props.gaLabel,
                                        "data-ga4-event-name": "global_navigation",
                                        "data-ga4-action": "Italian",
                                        "data-ga4-details": props.gaLabel
                                    }, _toDisplayString(props.t.italianText), 9, _hoisted_13)
                                ]),
                                _createElementVNode("li", null, [
                                    _createElementVNode("a", {
                                        href: '/es-ES' + props.uri,
                                        "data-ga-category": "Global Language Switch",
                                        "data-ga-action": "Spanish",
                                        "data-ga-label": props.gaLabel,
                                        "data-ga4-event-name": "global_navigation",
                                        "data-ga4-action": "Spanish",
                                        "data-ga4-details": props.gaLabel
                                    }, _toDisplayString(props.t.spanishText), 9, _hoisted_14)
                                ]),
                                _createElementVNode("li", null, [
                                    _createElementVNode("a", {
                                        href: '/pt-BR' + props.uri,
                                        "data-ga-category": "Global Language Switch",
                                        "data-ga-action": "Portuguese",
                                        "data-ga-label": props.gaLabel,
                                        "data-ga4-event-name": "global_navigation",
                                        "data-ga4-action": "Portuguese",
                                        "data-ga4-details": props.gaLabel
                                    }, _toDisplayString(props.t.portugueseText), 9, _hoisted_15)
                                ]),
                                _createElementVNode("li", null, [
                                    _createElementVNode("a", {
                                        href: '/de-DE' + props.uri,
                                        "data-ga-category": "Global Language Switch",
                                        "data-ga-action": "Deutsch",
                                        "data-ga-label": props.gaLabel,
                                        "data-ga4-event-name": "global_navigation",
                                        "data-ga4-action": "Deutsch",
                                        "data-ga4-details": props.gaLabel
                                    }, _toDisplayString(props.t.deutschText), 9, _hoisted_16)
                                ]),
                                _createElementVNode("li", null, [
                                    _createElementVNode("a", {
                                        href: '/ko-KR' + props.uri,
                                        "data-ga-category": "Global Language Switch",
                                        "data-ga-action": "Korean",
                                        "data-ga-label": props.gaLabel,
                                        "data-ga4-event-name": "global_navigation",
                                        "data-ga4-action": "Korean",
                                        "data-ga4-details": props.gaLabel
                                    }, _toDisplayString(props.t.koreanText), 9, _hoisted_17)
                                ]),
                                _createElementVNode("li", null, [
                                    _createElementVNode("a", {
                                        href: '/id-ID' + props.uri,
                                        "data-ga-category": "Global Language Switch",
                                        "data-ga-action": "Indonesian",
                                        "data-ga-label": props.gaLabel,
                                        "data-ga4-event-name": "global_navigation",
                                        "data-ga4-action": "Indonesian",
                                        "data-ga4-details": props.gaLabel
                                    }, _toDisplayString(props.t.indonesianText), 9, _hoisted_18)
                                ]),
                                (props.isJapaneseEnabled)
                                    ? (_openBlock(), _createElementBlock("li", _hoisted_19, [
                                        _createElementVNode("a", {
                                            href: '/ja-JP' + props.uri,
                                            "data-ga-category": "Global Language Switch",
                                            "data-ga-action": "Japanese",
                                            "data-ga-label": props.gaLabel,
                                            "data-ga4-event-name": "global_navigation",
                                            "data-ga4-action": "Japanese",
                                            "data-ga4-details": props.gaLabel
                                        }, " 日本語 ", 8, _hoisted_20)
                                    ]))
                                    : _createCommentVNode("", true)
                            ])
                        ])
                    ])
                ])
            ]));
        };
    }
});
