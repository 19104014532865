import { createApp, defineComponent } from 'vue/dist/vue.esm-bundler';
import { onMounted, ref } from 'vue';
import NavMobile from "@components/Navigation/NavMobile.vue";
import NavHeaderMobile from '@components/Navigation/NavHeaderMobile.vue';
import NavRecentActivity from '@components/Navigation/NavRecentActivity.vue';
import NavGroupMobile from '@components/Navigation/NavGroupMobile.vue';
import NavItemMobile from '@components/Navigation/NavItemMobile.vue';
import { recentActivity, saveRecentActivity } from '@ts/Navigation/recent-activity';
const MobileNavigation = defineComponent({
    name: 'MobileNavigation',
    components: {
        NavMobile,
        NavHeaderMobile,
        NavRecentActivity,
        NavGroupMobile,
        NavItemMobile,
    },
    setup() {
        const _window = window;
        const currentLinkID = _window.zumba_navigation.currentLinkID;
        const currentSectionID = _window.zumba_navigation.currentSectionID;
        const state = ref({
            activeGroup: currentSectionID, //active section expanded (could be manually)
            toolsActive: true, //zync or zin tools active
            toolsExpanded: true, //zync or zin tools expanded
            activeLinkID: currentLinkID, //active link based on actual url.
            activeLinkSectionID: currentSectionID, //active section from the current link
        });
        const handleItemClick = (link) => {
            saveRecentActivity(link);
        };
        const handleToggleUserMenu = (group) => {
            if (group.expanded) {
                state.value.activeGroup = group.id;
            }
            else {
                state.value.activeGroup = '';
            }
            state.value.toolsActive = state.value.toolsExpanded && !state.value.activeGroup;
        };
        const handleToggleToolsMenu = (group) => {
            state.value.toolsExpanded = group.expanded;
            if (group.expanded) {
                state.value.toolsActive = !state.value.activeGroup;
            }
            else {
                state.value.toolsActive = false;
            }
        };
        onMounted(() => {
            if (currentSectionID) {
                state.value.toolsActive = false;
            }
        });
        return {
            state,
            recentActivity,
            handleItemClick,
            handleToggleUserMenu,
            handleToggleToolsMenu,
        };
    }
});
if (document.getElementById('side-navigation')) {
    createApp(MobileNavigation).mount('#side-navigation');
}
