import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, mergeProps as _mergeProps, createBlock as _createBlock, toDisplayString as _toDisplayString, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = { class: "gf-footer-nav" };
const _hoisted_2 = { class: "row" };
const _hoisted_3 = {
    key: 0,
    class: "columns gf-footer-strong-logo"
};
const _hoisted_4 = {
    href: "#",
    class: "strong-link"
};
const _hoisted_5 = { class: "columns medium-8 large-9 gf-footer-nav-columns" };
const _hoisted_6 = {
    key: 1,
    class: "gf-footer-nav-app-stores hide-for-medium-up"
};
const _hoisted_7 = { href: "https://apps.apple.com/app/zumba-the-dance-fitness-party/id6447769025" };
const _hoisted_8 = { href: "https://play.google.com/store/apps/details?id=com.zumba.consumerapp" };
const _hoisted_9 = { class: "medium-4 large-3 columns gf-footer-social" };
const _hoisted_10 = { class: "gf-social" };
const _hoisted_11 = {
    key: 0,
    class: "gf-footer-nav-title gf-social-title hide-for-small z-b3"
};
const _hoisted_12 = { class: "gf-social-wrapper" };
const _hoisted_13 = ["href", "data-ga4-action"];
const _hoisted_14 = { class: "brands" };
const _hoisted_15 = {
    href: "//zumba.com",
    title: "Zumba"
};
const _hoisted_16 = {
    href: "//strongnation.com",
    title: "Strong Nation",
    target: "_blank",
    rel: "noopener"
};
const _hoisted_17 = {
    href: "//zumbawear.com/",
    title: "Zumba Wear",
    target: "_blank",
    rel: "noopener"
};
const _hoisted_18 = {
    href: "//zumbawear.com/pages/strong-id-homepage?brand=strong",
    title: "Strong ID",
    target: "_blank",
    rel: "noopener"
};
import { onMounted, ref } from 'vue';
import { computed } from "vue";
import FooterNavList from '@components/Footer/FooterNavList.vue';
import FooterNewsletterForm from '@components/Footer/FooterNewsletterForm.vue';
import FooterStrongNationBrandSVG from '@icons/footer/strong-nation-brand.svg';
import FooterZumbaBrandSVG from '@icons/footer/zumba-brand.svg';
import FooterStrongIDBrandSVG from '@icons/footer/strong-id-brand.svg';
import FooterWearZumbaBrandSVG from '@icons/footer/wear-zumba-brand.svg';
import FacebookIconSVG from '@icons/footer/facebook-icon.svg';
import InstagramIconSVG from '@icons/footer/instagram-icon.svg';
import PinterestIconSVG from '@icons/footer/pinterest-icon.svg';
import TiktokIconSVG from '@icons/footer/tiktok-icon.svg';
import TwitterIconSVG from '@icons/footer/twitter-icon.svg';
import YoutubeIconSVG from '@icons/footer/youtube-icon.svg';
import StrongNationLogoSVG from '@icons/strong-nation-logo.svg';
import AppStoreMobileLogoSVG from '@icons/footer/app-store-mobile.svg';
import GooglePlayMobileLogoSVG from '@icons/footer/google-play-mobile.svg';
import { isUserAuthenticated } from '@ts/Util/security';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ZFooter',
    props: {
        theme: {
            type: String,
            default: 'zumba'
        },
        t: {
            type: Object,
            default: () => ({
                brandsText: 'Our brands',
                socialTitle: 'Let\'s Be Friends',
                newsletter: {
                    title: 'Get the latest Zumba News, Promos & Events.',
                    thankYouMessage: 'Thanks for Joining!',
                    inputPlaceholder: 'Enter your email',
                    buttonText: 'Join',
                    invalidEmailText: 'The email address you entered is invalid.',
                    unsubscribeNoticeText: 'You can unsubscribe at any time.'
                }
            })
        },
        navLists: {
            type: (Array),
            default: []
        },
        socialList: {
            type: (Array),
            default: []
        },
        newsletterSource: {
            type: String,
            default: 'site-footer',
        },
        headerFooterStyle: {
            type: String,
            default: 'full'
        },
    },
    setup(__props) {
        const props = __props;
        const authenticated = ref(false);
        const socialComponents = {
            FacebookIconSVG,
            InstagramIconSVG,
            PinterestIconSVG,
            TiktokIconSVG,
            TwitterIconSVG,
            YoutubeIconSVG
        };
        onMounted(() => {
            authenticated.value = isUserAuthenticated();
        });
        const footerStyleClasses = computed(() => {
            const styles = {
                'hide-nav-ele': props.headerFooterStyle === 'minimal'
            };
            styles[props.theme] = true;
            return styles;
        });
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("footer", {
                class: _normalizeClass(footerStyleClasses.value)
            }, [
                _createElementVNode("div", _hoisted_1, [
                    _createElementVNode("div", _hoisted_2, [
                        (__props.theme === 'strong')
                            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                                _createElementVNode("a", _hoisted_4, [
                                    _createVNode(_unref(StrongNationLogoSVG), { class: "strong-logo" })
                                ])
                            ]))
                            : _createCommentVNode("", true),
                        _createElementVNode("div", _hoisted_5, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.navLists, (navList, key) => {
                                return (_openBlock(), _createBlock(FooterNavList, _mergeProps({ ref_for: true }, navList, {
                                    key: key,
                                    theme: __props.theme
                                }), null, 16, ["theme"]));
                            }), 128))
                        ]),
                        (props.theme === 'zumba')
                            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                                _createElementVNode("a", _hoisted_7, [
                                    _createVNode(_unref(AppStoreMobileLogoSVG), { class: "gf-footer-app-store-logo" })
                                ]),
                                _createElementVNode("a", _hoisted_8, [
                                    _createVNode(_unref(GooglePlayMobileLogoSVG), { class: "gf-footer-google-play-logo" })
                                ])
                            ]))
                            : _createCommentVNode("", true),
                        _createElementVNode("div", _hoisted_9, [
                            _createElementVNode("aside", _hoisted_10, [
                                (__props.theme === 'zumba')
                                    ? (_openBlock(), _createElementBlock("h3", _hoisted_11, _toDisplayString(props.t.socialTitle), 1))
                                    : _createCommentVNode("", true),
                                _createElementVNode("nav", _hoisted_12, [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.socialList, (socialItem, key) => {
                                        return (_openBlock(), _createElementBlock("a", {
                                            key: key,
                                            href: socialItem.link,
                                            rel: "noopener",
                                            target: "_blank",
                                            "data-ga4-event-name": "social_cta_click",
                                            "data-ga4-action": socialItem.gaAction
                                        }, [
                                            (_openBlock(), _createBlock(_resolveDynamicComponent(socialComponents[socialItem.icon])))
                                        ], 8, _hoisted_13));
                                    }), 128))
                                ])
                            ]),
                            (!authenticated.value)
                                ? (_openBlock(), _createBlock(FooterNewsletterForm, {
                                    key: 0,
                                    source: __props.newsletterSource,
                                    t: {
                                        title: props.t.newsletter.title,
                                        thankYouMessage: props.t.newsletter.thankYouMessage,
                                        inputPlaceholder: props.t.newsletter.inputPlaceholder,
                                        buttonText: props.t.newsletter.buttonText,
                                        invalidEmailText: props.t.newsletter.invalidEmailText,
                                        unsubscribeNoticeText: props.t.newsletter.unsubscribeNoticeText,
                                    }
                                }, null, 8, ["source", "t"]))
                                : _createCommentVNode("", true),
                            _createElementVNode("aside", _hoisted_14, [
                                _createElementVNode("p", null, _toDisplayString(props.t.brandsText) + ":", 1),
                                _createElementVNode("a", _hoisted_15, [
                                    _createVNode(_unref(FooterZumbaBrandSVG))
                                ]),
                                _createElementVNode("a", _hoisted_16, [
                                    _createVNode(_unref(FooterStrongNationBrandSVG))
                                ]),
                                _createElementVNode("a", _hoisted_17, [
                                    _createVNode(_unref(FooterWearZumbaBrandSVG))
                                ]),
                                _createElementVNode("a", _hoisted_18, [
                                    _createVNode(_unref(FooterStrongIDBrandSVG))
                                ])
                            ])
                        ])
                    ])
                ])
            ], 2));
        };
    }
});
