import { refreshCsrfToken } from "@ts/Util/security";
const initiateRefreshInterval = () => {
    setInterval(refreshCsrfToken, 1000 * 60 * 30);
};
const refreshCsrfTokenOnVisibilityChange = () => {
    document.addEventListener('visibilitychange', () => {
        if (document.visibilityState === 'visible') {
            refreshCsrfToken();
        }
    });
};
document.addEventListener('DOMContentLoaded', () => {
    initiateRefreshInterval();
    refreshCsrfTokenOnVisibilityChange();
});
