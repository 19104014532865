import { createApp, defineComponent } from 'vue/dist/vue.esm-bundler';
import HeaderToggle from '@modules/Layout/HeaderToggle.vue';
import HeaderProfile from '@modules/Layout/Profile.vue';
import HeaderUserMenu from '@modules/Layout/UserMenu.vue';
import HeaderGym from '@modules/Layout/Gym.vue';
import Tag from '@components/Tags/Tag.vue';
const HeaderToggleComponent = defineComponent({
    name: 'HeaderToggleComponent',
    components: {
        HeaderToggle,
        HeaderProfile,
        HeaderUserMenu,
        HeaderGym,
        Tag
    }
});
createApp(HeaderToggleComponent).mount('#header-toggle-component');
