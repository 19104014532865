import { defineComponent as _defineComponent } from 'vue';
import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode } from "vue";
const _hoisted_1 = { class: "nav-group" };
const _hoisted_2 = {
    key: 0,
    class: "nav-group-items"
};
import { onMounted } from 'vue';
import { ref, watchEffect } from 'vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'NavGroupMobile',
    props: {
        id: { default: '' },
        title: { default: '' },
        active: { type: Boolean, default: false },
        expanded: { type: Boolean, default: false },
        indepententActiveState: { type: Boolean, default: false },
        isTopLevel: { type: Boolean, default: false }
    },
    emits: ['toggle-expand'],
    setup(__props, { emit: __emit }) {
        const props = __props;
        const emit = __emit;
        const isExpanded = ref(props.expanded);
        const isActive = ref(!props.indepententActiveState ? props.expanded : props.active);
        onMounted(() => {
            isExpanded.value = props.expanded;
            isActive.value = props.active;
            if (!props.indepententActiveState) {
                isActive.value = isExpanded.value;
            }
        });
        watchEffect(() => {
            isActive.value = props.active;
            if (!props.indepententActiveState) {
                isActive.value = isExpanded.value;
            }
        });
        const toggleExpand = () => {
            isExpanded.value = !isExpanded.value;
            if (!props.indepententActiveState) {
                isActive.value = isExpanded.value;
            }
            emit('toggle-expand', { id: props.id, expanded: isExpanded.value });
        };
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
                _createElementVNode("li", {
                    class: _normalizeClass(["nav-group-title z-b3", { 'expanded': isExpanded.value,
                            'is-top-level': props.isTopLevel,
                            'active': isActive.value
                        }]),
                    onClick: toggleExpand
                }, [
                    _renderSlot(_ctx.$slots, "title", {}, () => [
                        _createTextVNode(_toDisplayString(props.title), 1)
                    ])
                ], 2),
                _createVNode(_Transition, { name: "expand" }, {
                    default: _withCtx(() => [
                        (isExpanded.value)
                            ? (_openBlock(), _createElementBlock("ul", _hoisted_2, [
                                _renderSlot(_ctx.$slots, "default")
                            ]))
                            : _createCommentVNode("", true)
                    ]),
                    _: 3
                })
            ]));
        };
    }
});
