import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = { class: "gf-footer-nav-set z-b3" };
const _hoisted_2 = { class: "gf-footer-nav-title" };
const _hoisted_3 = { class: "gf-footer-nav-item" };
const _hoisted_4 = ["href", "data-ga4-action"];
const _hoisted_5 = {
    key: 0,
    class: "gf-footer-nav-app-stores"
};
const _hoisted_6 = { href: "https://apps.apple.com/app/zumba-the-dance-fitness-party/id6447769025" };
const _hoisted_7 = { href: "https://play.google.com/store/apps/details?id=com.zumba.consumerapp" };
import AppStoreLogoSVG from '@icons/footer/app-store.svg';
import GooglePlayLogoSVG from '@icons/footer/google-play.svg';
export default /*@__PURE__*/ _defineComponent({
    __name: 'FooterNavList',
    props: {
        title: {},
        list: {},
        theme: {}
    },
    setup(__props) {
        const props = __props;
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
                _createElementVNode("h3", _hoisted_2, _toDisplayString(props.title), 1),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item, key) => {
                    return (_openBlock(), _createElementBlock("ul", {
                        key: key,
                        class: "gf-footer-nav-list"
                    }, [
                        _createElementVNode("li", _hoisted_3, [
                            _createElementVNode("a", {
                                class: "gf-footer-nav-link",
                                href: item.link,
                                "data-ga4-event-name": "footer_nav",
                                "data-ga4-action": item.text
                            }, _toDisplayString(item.text), 9, _hoisted_4)
                        ])
                    ]));
                }), 128)),
                (props.title === 'Classes' && props.theme === 'zumba')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                        _createElementVNode("a", _hoisted_6, [
                            _createVNode(_unref(AppStoreLogoSVG))
                        ]),
                        _createElementVNode("a", _hoisted_7, [
                            _createVNode(_unref(GooglePlayLogoSVG))
                        ])
                    ]))
                    : _createCommentVNode("", true)
            ]));
        };
    }
});
